.EnvChat{
  position: absolute;
  top: 0;
  left: 30%;
  transform: translate(-50%, -50%);
  top: 50%;
}

.mulher{
  position: absolute;
  right: 0;
  bottom: 0;
}

.live{
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 22px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: white;
  position: absolute;
  top: 10px;
  right: 20px;
}

.g{
  margin-left: 6px;
  font-size: 35px;
  color: greenyellow;
  margin-top: -3px;
}